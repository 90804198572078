<template>
  <div class="Utilities">
    <div class="Utilities_top">
      <van-image
        width="100%"
        fit="cover"
        :src="require('@/assets/img/utilities_01.jpg')"
      />
    </div>
    <div class="Utilities_nav">
      <div
        class="Utilities_nav_box"
        v-for="item in UtilitiesNav"
        :key="item.name"
        @click="goNav(item)"
      >
        <van-image fit="cover" :src="item.img" />
        <p class="van-ellipsis">{{ item.name }}</p>
      </div>
    </div>
    <div>
      <van-swipe :autoplay="3000">
        <van-swipe-item v-for="(image, index) in images" :key="index">
          <van-image width="100%" lazy-load fit="cover" :src="image" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <copyrightIp />
  </div>
</template>
<script>
import { Image as VanImage, Swipe, SwipeItem } from "vant";
import copyrightIp from "@/components/copyrightIp";
export default {
  components: {
    [VanImage.name]: VanImage,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    copyrightIp,
  },
  data() {
    return {
      UtilitiesNav: [
        {
          name: "申请代发薪证明",
          img: require("@/assets/img/utilities_02.jpg"),
        },
        {
          name: "完成证明",
          img: require("@/assets/img/utilities_03.jpg"),
        },
        {
          name: "综合算薪计算器",
          img: require("@/assets/img/utilities_04.jpg"),
        },
        {
          name: "外籍人算薪",
          img: require("@/assets/img/utilities_05.jpg"),
        },
        {
          name: "工资单",
          img: require("@/assets/img/utilities_06.jpg"),
        },
        {
          name: "薪税云",
          img: require("@/assets/img/utilities_07.jpg"),
        },
        {
          name: "占位1",
          img: require("@/assets/img/utilities_08.jpg"),
        },
        {
          name: "占位2",
          img: require("@/assets/img/utilities_08.jpg"),
        },
        {
          name: "更多",
          img: require("@/assets/img/utilities_09.jpg"),
        },
      ],
      images: [
        require("@/assets/img/utilities_10.jpg"),
        require("@/assets/img/utilities_10.jpg"),
      ],
    };
  },
  mounted() {},
  methods: {
    goNav(data) {
      this.$router.push("/calculator/moon");
    },
    toMoney(num) {
      let money = num;
      money = money.toFixed(2);

      money = parseFloat(money);

      money = money.toLocaleString();
      return money; // 返回的是字符串23,245.12保留2位小数
    },
  },
};
</script>
<style lang="scss" scoped>
.Utilities {
  .Utilities_top {
    img {
      width: 100%;
    }
  }
  .Utilities_nav {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    padding: 10px;
    .Utilities_nav_box {
      text-align: center;
      background: #fff;
      padding: 20px 0;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgb(0 0 0 / 12%), 0 0 6px rgb(0 0 0 / 4%);
      p {
        padding-top: 10px;
      }
    }
  }
}
</style>
